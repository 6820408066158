@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

.App {
    text-align: center;
}

body {
    /*font-family: OpenSans, serif;*/
    font-family: 'Poppins', sans-serif;
}

.highcharts-credits {
    display: none;
}

.defaultBackground {
    background-color: #6574cf !important;
}

thead td {
    font-weight: 700 !important;
}

.bg-gray {
    background-color: #f7f7f7 !important;
}

.bg-gray-2 {
    background-color: #efefef !important;
}

.accordion .card-header {
    cursor: pointer;
}

.accordion .card-header:hover {
    background-color: #6574cf87
}

.accordion .card-body {
    max-height: 500px;
    overflow: auto;
}

.list-group {
    max-height: 332px;
    overflow: auto;
}

.list-group-item {
    word-wrap: break-word;
}

.list-group-item.active {
    background-color: #6574cf !important;
    border-color: #6574cf !important;
}

.zIndex {
    z-index: 99999 !important;
}

.slick-next, .slick-prev {
    z-index: 99 !important;
}

.slick-next:before, .slick-prev:before {
    color: #6574cf !important;
}

.mr-15 {
    margin: 0 15px !important;
}

.p-15 {
    padding: 15px !important;
}

.no-radius-left {
    height: 38px !important;
    border-radius: 0 4px 4px 0 !important;
}


.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.react-calendar {
    max-width: 500px !important;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.rce-mbox {
    max-width: 200px;
}

.myTable table tr:last-child td,.myTable table tr:last-child {
    border-bottom: none;
}

.myTable table td:last-child {
    width: 50%;
}

.DateInput_input {
    font-size: 16px !important;
    padding: 5px 11px !important;
}

.myTable table td:nth-child(2) {
    width: 50%;
}

.myTable tr {
    height: auto !important;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

#containerElement.addition {
    position: static !important;
}

.formMob {
    width: 50%;
    margin: 25px 0 0 0;
    padding: 35px 40px;
    background-color: #fff;
    box-shadow: 0 0 20px #ccc;
    border-radius: 6px;
}

.formMob label {
    display: block;
    margin: 20px 0 2px;
    font-weight: 600;
    font-size: 15px !important;
    align-items: center;
    color: #8F9BB3;
}

.formMob select {
    padding-left: 10px;
    background-color: #fafafa;
}

.w-50 {
    width: 50%;
}

.shadow {
    box-shadow: 0 0 15px #ccc;
    background-color: #fff;
}

.detailsA {
    margin: 15px 0 0;
    padding: 16px 10px;
    border-radius: 6px;
}

.scrollMob {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 15px;
}

.rmsc .dropdown-heading {
    padding-left: 25px !important;
}

.rmsc .clear-selected-button {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 24px;
    margin-top: -12px;
}

.liLine {
    padding-bottom: 10px !important;
    margin-bottom: 10px !important;
    border-bottom: 1px solid #ccc;
}

.liLine:last-child {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    border: none;
}

.custom-control-input:checked ~ .custom-control-label::before {
    border-color: #ced4da !important;
    background-color: #6574cf !important;
}

.fz13 {
    font-size: 13px !important;
}

@media screen and (max-width: 1520px) {
    .fz10 {
        font-size: 10px !important;
    }
}

@media screen and (max-width: 1400px) {
    .scrollMob {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 1120px) {
    .scrollMob {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 1199px) {
    .fz10 {
        font-size: 14px !important;
    }
}

.sidebar {
    position: fixed !important;
    height: 100vh;
    top: 70px;
    padding: 35px 32px 35px 18px;
    background-color: #fff;
}

@media screen and (max-width: 991px) {
    .sidebar {
        position: static !important;
        height: auto;
    }
}

.noReg {
    margin: 110px 0 0 0;
    padding: 0 10px
}

@media (max-width: 768px) {
    .noReg {
        margin: 0;
    }
}

.header-video {
    padding-left: 0 !important;
}

.MsoNormal {
    margin-top: 30px;
}

.MsoNormal h3 {
    margin-bottom: 30px;
}

.MsoNormal p {
    margin-top: 10px;
    font-size: 20px;
    word-break: break-word;
    white-space: pre-line;
}

.list-type {
    list-style-type: none;
}

.list {
    padding-left: 0 !important;
    font-size: 20px;
    word-break: break-word;
}

.list li {
    margin: 10px 0 0 30px;
    word-break: break-word;
    white-space: pre-line;
}

.table-block {
    width: 100%;
    overflow: auto;
    font-size: 18px;
}

.table-item {
    min-width: 1200px;
}

.table-item tr th {
    padding: 10px;
    word-break: break-word;
    white-space: pre-line;
}

.table-item tr td {
    padding: 10px;
    word-break: break-word;
    white-space: pre-line;
}


