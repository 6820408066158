body {
    color: #575757;
    background-color: #6574cf;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: fixed;
    width: 100%;
    height: 100%;
}

a {
    text-decoration: none !important;
}

#root {
    background-color: white;
    width: 100%;
    height: 100%;
    overflow: auto;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.react-calendar__tile--now {
    background-color: transparent !important;
    border: 1px solid #6574cf !important;
    border-radius: 4px;
}

.react-calendar__tile--active {
    background-color: #6574cf !important;
    border-radius: 4px;
}

.react-calendar {
    width: 100% !important;
    border: 1px solid #e4e9f2 !important;
    border-radius: 5px;
}

.rce-container-input {
    border: 1px solid #e4e9f2;
    margin: 20px;
    width: calc(100% - 40px);
    min-width: auto !important;
    /*min-width: 1100px !important;*/
    min-height: 200px;
    border-radius: 8px;
    align-items: flex-end !important;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    flex-flow: column wrap;
    align-content: stretch;
    box-sizing: border-box;
    flex-direction: row;
    background: #fff;
}

.rce-input-textarea {
    padding: 10px !important;
}

.rce-input-buttons {
    margin: 10px !important;
}

@media screen and (min-width: 768px) {
    /* .react-calendar__tile {
    border-radius: 50% !important;
  }*/
    /* .react-calendar__tile--active {
    background-color: #1ec760 !important;
  }*/
}

.rce-container-input textarea {
    flex: 0 0 100%;
    height: 120px !important;
}

.react-jitsi-container {
    position: absolute;
    background-color: white;
}

#react-jitsi-frame {
    display: block !important;
}

.rce-container-mlist {
    position: relative;
    display: flex;
}

.react-calendar__tile--range {
    filter: opacity(0.5);
}

.react-calendar__tile--rangeStart {
    filter: opacity(1) !important;
}

.react-calendar__tile--rangeEnd {
    filter: opacity(1) !important;
}

.rce-mbox-photo--img img {
    width: 150px;
}

.rce-container-mbox .rce-mbox-right {
    color: #fff;
    background-color: #0077ff;
}

.rce-container-mbox .rce-mbox-right svg {
    fill: #0077ff;
}

.rce-container-mbox .rce-mbox-right .rce-mbox-time {
    color: rgb(255 255 255/45%);
}